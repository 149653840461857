.bloque-texto-rrhh-container {
  max-width: 800px;
  margin-right: auto;
  width: 100%;
  margin-left: auto;
  margin-top: -140px;
}
.formulario-boxs-text {
  margin-bottom: 80px;
}
.formulario-box-blue {
  display: flex;
  background-color: #00193a;
  align-items: flex-start;
  column-gap: 10px;
  width: 45%;
  padding: 30px;
  height: 250px;
  border-radius: 5px;
}

.formulario-box-blue h1 {
  color: white;
  font-size: 30px;
  font-weight: 300;
  margin: 0px;
}

.formulario-box-blue svg {
  color: white;
  font-size: 80px;
  height: auto;
  margin-top: 10px;
}

.formulario-box-white {
  background-color: white;
  margin-top: -70px;
  box-shadow: 6px 6px 10px 0px rgb(0 0 0 / 17%);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  border-radius: 5px;
}

.formulario-box-white p {
  color: #00193a;
  font-size: 18px;
}

@media (max-width: 768px) {
  .bloque-texto-rrhh-container {
    width: 100%;
    margin-top: -100px;
  }

  .formulario-boxs-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .formulario-box-blue {
    width: 80%;    
  }

  .formulario-box-blue h1 {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
  }

  .formulario-box-blue svg {
    font-size: 50px;
    margin-top: 5px;
  }

  .formulario-box-white {
    margin-top: -130px;
    padding: 20px;
  }

  .formulario-box-white p {
    font-size: 12px;
    line-height: 18px;
  }
}
