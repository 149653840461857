.bottomFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  text-align: center;
  background-color: white;
  margin-top: 80px;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .footer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .bottomFooter {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .bottomFooter {
    font-size: 10px;
    height: 150px;
  }
}
