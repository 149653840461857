.container {
  width: 100%;
}

.navCont {
  background: #00193a;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  max-width: 1330px;
  padding: 20px;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navLinks {
  margin-left: auto;
}

.navLinks .active {
  padding-left: 18px !important;
  padding-right: 18px !important;
  color: white;
  font-weight: 600;
  text-decoration: none;
  margin-top: 10rem;
  font-size: 16px;
}

.item {
  padding-left: 18px !important;
  padding-right: 18px !important;
  color: white;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
}