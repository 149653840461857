.container {
  background-color: pink;
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  margin-bottom: 365px;
}

.form {
  background-color: #ffffff;
  position: absolute;
  width: 685px;
  height: 325px;
  top: -220px;
  margin-left: 160px;
  box-shadow: 6px 6px 10px 0px rgb(0 0 0 / 17%);
  border-radius: 5px;
  padding: 20px 30px 0px 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.form label {
  font-size: 14px;
}

.fields {
  display: flex;
  gap: 15px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.input {
  background-color: #f4f4f6;
  border: 1px solid #eaeaea;
  height: 40px;
  width: 395px;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;

  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.textareaContainer {
  display: flex;
  flex-direction: column;
}

.textarea {
  background-color: #f4f4f6;
  border: 1px solid #eaeaea;
  height: 110px;
  border-radius: 5px;
  width: 270px;
  outline: none;
  resize: none;
  padding-left: 10px;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 300;

  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.button button {
  background-color: #00193a;
  border: 0px;
  color: #fff;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  width: 70px;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.error {
  color: rgb(221, 0, 0);
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-bottom: 540px;
  }
  .form {
    width: 270px;
    height: 520px;
    top: -140px;
    margin-left: 0px;
  }
  .form label {
    font-size: 12px;
  }
  .fields {
    flex-direction: column;
  }
  .input {
    height: 25px;
    width: 250px;
    padding-left: 10px;
    font-size: 12px;
  }
  .textarea {
    height: 110px;
    width: 250px;
    font-size: 12px;
  }
  .button button {
    font-size: 12px;
    padding: 8px;
    width: 70px;
  }
  .error {
    font-size: 12px;
  }
}
