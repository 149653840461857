.container {
  background-color: #001323;
  width: 100%;
  height: 40px;
}

.centerElements {
  align-items: center;
  max-width: 1330px;
  height: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.centerElements a,
span {
  font-size: 10px;
  font-weight: 400;
  line-height: 1em;
  color: white;
}

.box1 {
  width: 300px;
  display: flex;
  align-items: center;
}

.box2 {
  width: 300px;
  display: flex;
  align-items: center;
}

.icons img {
  padding-right: 5px;
  width: 15px;
}

@media only screen and (max-width: 877px) {
  .container {
    height: 20px;
  }
  .centerElements {
    max-width: 100%;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 5px;
  }
  .box1 {
    width: 110px;
  }
  .box2 {
    width: 126px;
  }
  .centerElements a,
  span {
    font-size: 8px;
  }
}
