.greyContainer {
  margin-top: -380px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 50%;
  height: 300px;
  background-color: #f4f4f6;
  border-radius: 10px 0px 0px 10px;
}

.boxGreyContainer {
  width: 100%;
}

.redesContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.seguinos {
  color: #001323;
  font-family: poppins, Sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.redes img {
  padding-left: 10px;
  padding-right: 10px;
}

.redes img {
  width: 45px;
}

.botonSubir {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: -14px;
}

.botonSubir p {
  color: #00193a;
  line-height: 0;
}

.arrowIcon {
  border-radius: 40px;
  background-color: #001323;
  width: 40px;
  height: 40px;
  font-size: 50px;
  display: flex;
  justify-content: center;
}

.arrowIcon img {
  width: 40px;
  height: 40px;
}

@media (min-width: 768px) and (max-width: 1250px) {
  .redesContainer {
    padding: 20px;
  }
  .redes {
    width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .greyContainer {
    margin-top: 0px;
    position: absolute;
    left: 10px;
    flex-direction: column-reverse;
    height: 228px;
    border-radius: 10px 10px 10px 10px;
  }
  .redes img {
    width: 30px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .boxGreyContainer.botonSubir {
    top: 0;
    position: absolute;
  }
  .redesContainer .seguinos {
    font-size: 14px;
    text-align: center;
  }
  .botonSubir p {
    font-size: 14px;
  }
  .arrowIcon {
    width: 30px;
    height: 30px;
  }
  .arrowIcon img {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .botonSubir p {
    font-size: 12px;
    padding-top: 5px;
  }
  .redesContainer .seguinos {
    font-size: 10px;
  }
}