.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 232px;
}

.title {
  background-color: #00193a;
  display: flex;
  justify-content: center;
  width: 455px;
  border-radius: 5px 5px 0px 0px;
}

.title h2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
}

.text {
  color: #7a7a7a;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 545px;
  border-radius: 5px;
  box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
}

.text p {
  padding-left: 45px;
  padding-right: 45px;
  font-size: 14px;
  margin-bottom: 25px;
  width: 450px;
}

.text b {
  color: #4f5471;
}

.buttonContainer {
  position: absolute;
  bottom: -20px;
  background-color: #00193a;
  border-radius: 4px;
  height: 40px;
  width: 215px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContent {
  display: flex;
  gap: 10px;
}

.buttonContent p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-bottom: 116px;
  }
  .title {
    width: 325px;
  }
  .title h2 {
    font-size: 14px;
    font-weight: 500;
  }
  .text {
    width: 355px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    width: 320px;
  }
  .buttonContainer {
    height: 30px;
    width: 190px;
  }
  .buttonContent img {
    width: 12.5px;
  }
  .buttonContent p {
    font-size: 12px;
    font-weight: 600;
  }
}
