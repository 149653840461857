.container {
  max-width: 750px;
  margin-top: 125px;
  background-color: #00193a;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0 0 50px;
  border-radius: 4px;
  height: 130px;
  margin-bottom: 80px;
}

.container img {
  width: 20px;
}

.container h1 {
  line-height: 0px;
  margin: 0px;
  color: #fff;
  font-size: 27px;
  font-weight: 300;
  line-height: 1.2em;
}



@media only screen and (min-width: 768px) and (max-width: 1290px) {
  .container {
    max-width: 695px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 310px;
    margin-top: 50px;
    padding: 50px 0 0 40px;
    height: 110px;
    margin-bottom: 40px;
  }
  .container img {
    width: 15px;
  }
  .container h1 {
    font-size: 25px;
  }
}
