.formulario-botones-container {
  max-width: 860px;
  margin-right: auto;
  width: 100%;
  margin-left: auto;
}

.formulario-botones-fila {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
}

.formulario-boton {
  width: 45%;
  background-color: #fff;
  border: none;
  box-shadow: 6px 6px 10px 0px rgb(0 0 0 / 17%);
  background-position: center left 20px;
  background-repeat: no-repeat;
  font-family: Poppins;
  transition: all 0.2s ease-in-out;
}

.boton-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.formulario-boton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.boton-ventas {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112843/Icons/Trazado-33971_e2emug.png");
}

.boton-administracion {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112876/Icons/Grupo-308_sshcyw.png");
}

.boton-arquitectura {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112897/Icons/Grupo-255_m9cr7h.png");
}

.boton-periodismo {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112926/Icons/Grupo-297_v4nxy0.png");
}

.boton-finanzas {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112965/Icons/Grupo-271_kvrljq.png");
}

.boton-marketing {
  background-image: url("https://res.cloudinary.com/grupo-delsud-sistemas/image/upload/v1659112982/Icons/Grupo-296_n6qvlb.png");
}

.formulario-boton p {
  color: #00193a;
  font-size: 20px;
  font-weight: 600;
}
.formulario-boton svg {
  font-size: 50px;
  fill: #00193a;
}

@media (max-width: 768px) {
  .formulario-botones-container {
    margin-bottom: 80px;
  }
  .formulario-botones-fila {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
    gap: 20px;
  }

  .formulario-boton {
    width: 80%;
    padding: 0px 20px;
  }

  .formulario-boton p {
    font-size: 16px;
  }

  .formulario-boton svg {
    font-size: 40px;
  }
  .boton-text-container {
    padding: 20px 10px;
  }
}
