.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.modal {
  background-color: #00193a;
  color: #fff;
  border-radius: 5px;
  padding-left: 55px;
  padding-top: 30px;
  width: 705px;
  height: 270px;
}

.title h3 {
  font-size: 28px;
  font-weight: 300;
  line-height: 40px;
}
