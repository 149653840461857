.slider-container {
  display: flex;
  max-width: 1200px;
  height: 80vh;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.slider-item-left,
.slider-item-right {
  width: 100%;
}

.slider-item-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.novedades p {
  color: white;
  font-family: poppins;
  font-weight: 400;
  font-size: 24px;
  line-height: 0;
}

.novedades {
  margin-top: 15px;
  margin-left: -10px;
  width: 140px;
  box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 17%);
  border-radius: 4px;
  background-color: #082040;
  padding: 1px 10px;
  text-align: center;
}

.slider-item-right-container {
  border-radius: 10px;
  box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 17%);
  display: flex;
  background-color: white;
  margin-left: -70px;
}

.red {
  margin-left: auto;
  margin-right: auto;
  font-family: poppins;
  font-weight: 100;
  font-size: 10px;
  line-height: 0.1;
}

.texto {
  padding: 25px 20px;
  font-family: poppins;
  font-size: 14px;
  color: #082040;
  font-weight: 300;
}

.slider-text-description {
  color: #082040;
  font-weight: 300;
  font-size: 14px;
}

.texto .gris {
  margin: 0px;
  color: gray;
  font-weight: 500;
  padding-top: 10px;
  font-size: 14px;
  display: block;
}

.titulo-slider {
  font-size: 20px;
}

.redes-container {
  height: 250px;
  margin-top: 42px;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.boton {
  margin-top: 30px;
  border-radius: 4px;
  padding: 10px 14px;
  width: 120px;
  background-color: #082040;
}
.boton a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
}

.boton2 {
  margin-top: 30px;
  border-radius: 4px;
  padding: 10px 14px;
  width: 200px;
  background-color: #082040;
}
.boton2 a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
}

.boton2 span {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: poppins;
  padding-left: 10px;
}

.boton span {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: poppins;
  padding-left: 40px;
}
.slider-novedades {
  max-width: 1400px !important;
}

.swiper-button-prev {
  border-radius: 10px;
  padding-left: 14px;
  padding-right: 17px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #082040;
}
.swiper-button-next {
  border-radius: 10px;
  padding-left: 17px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #082040;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
}

@media only screen and (max-width: 768px) {
  .slider-novedades {
    margin: 50px 0px 0px 0px;
  }

  .slider-novedades .slider-container {
    flex-direction: column;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .slider-novedades .texto {
    padding: 0px 40px;
  }

  .novedades {
    margin-left: auto;
    margin-right: auto;
  }
  .slider-item-right {
    margin-top: -50px;
  }
  .slider-container {
    width: 100%;
  }
  .slider-item-right-container {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }
  .slider-item-right-container .red {
    margin-right: auto;
    margin-left: 0;
    font-size: 12px;
    font-weight: 500;
    color: rgb(99, 99, 99);
  }

  .slider-container hr {
    transform: rotate(0deg) !important;
    margin-top: 5px !important;
  }
  .slider-novedades .redes-container {
    height: auto;
    margin-top: 5px;
    writing-mode: unset;
    transform: rotate(0deg);
    flex-direction: column;
    padding: 0px 40px;
  }
}
