.blueContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 66%;
  height: 450px;
  background-color: #001323;
  border-radius: 0px 10px 10px 0px;
}

.blueContainer p {
  margin-top: 0px;
  font-size: 14px;
  font-family: poppins;
  color: white;
}

.blueInfo {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.elementContainer {
  float: left;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
}

.elementIcon img {
  width: 25px;
}

.elementText {
  display: flex;
  flex-direction: column;
}

.elementText p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .blueContainer {
    height: 210px;
    width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .blueInfo {
    width: 170px;
    margin-left: auto;
    margin-right: 0;
  }
  .blueInfo p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .blueInfo {
    width: 140px;
  }
  .elementContainer {
    gap: 5px;
  }
  .elementIcon img {
    width: 20px;
  }
  .blueInfo p {
    font-size: 9px;
  }
}