body {
  margin: 0;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  cursor: url("./assets/cursor.svg"), auto;
}

::-webkit-scrollbar {
  display: none;
}

a {
  cursor: url("./assets/cursor.svg"), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 992px) {
  .cursor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #54595f;
    position: absolute;
    z-index: 1000;
    pointer-events: none; /* Permite que los eventos del ratón pasen a través del cursor, necesario debido a la configuracion del z-index para que el círculo del cursor se vea por encima de todos los elementos */
    transition: background-color 300ms ease-out, transform 200ms ease-in;
    background-color: transparent;
    transform: scale(1);
  }

  .hoverActive {
    background-color: #42424280;
    border: none;
    transform: scale(1.2);
  }
}
