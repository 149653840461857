.contenedor {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 1290px) {
  .contenedor {
    max-width: 800px;
  }
}

@media only screen and (max-width: 768px) {
  .contenedor {
    max-width: 350px;
  }
}
