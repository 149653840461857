.sectionContainer {
  position: relative;
  margin-bottom: 250px;
}

.container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 750px;
  width: 100%;
}

.hero {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  width: 1330px;
  text-shadow: 1px 1px grey
}

.title {
  font-weight: 800;
  color: #ffffff;
  font-size: 45px;
  line-height: 1.4;
}

.button {
  margin-right: auto;
}

.button a {
  font-size: 15px;
  font-weight: 600;
  fill: #082040;
  color: #082040;
  background-color: #f4f4f6;
  border-radius: 5px 5px 5px 5px;
  padding: 12px 15px;
  text-decoration: none;
}

.arrowButton {
  margin-top: 0px;
  margin-right: auto;
  position: relative;
  animation-name: move;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.arrowButton img {
  width: 45px;
}

@keyframes move {
  0% {
    position: relative;
    top: 0;
  }
  100% {
    top: 2%;
  }
}

.videoContainer {
  width: 100% !important;
  height: 500px !important;
}

.videoContainer video {
  width: 100% !important;
  height: 950px;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  object-fit: cover;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
  width: 100% !important;
  height: 500px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-cnd76u-MuiPaper-root
  img {
  width: 50px !important;
}

.css-cnd76u-MuiPaper-root {
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-cnd76u-MuiPaper-root {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

/*@media (min-width: 1290px) {
  .videoContainer video {
    height: 100vh;
  }
}*/

@media only screen and (max-width: 768px) {
  .container {
    justify-content: center;
  }
  .hero {
    padding-left: 10px;
    padding-right: 10px;
    width: 280px;
  }
  .videoContainer {
    width: 100% !important;
    height: 70vh !important;
  }
  .videoContainer video {
    width: 100% !important;
    height: 100vh;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    object-fit: cover;
  }
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .button a {
    font-size: 12px;
    padding: 10px;
  }
  
  .arrowButton img {
    width: 30px;
  }
}
