.container {
  display: flex;
  width: 860px;
  height: 145px;
  border-radius: 10px;
  box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
}

.grayContainer {
  background-color: #f4f4f6;
  width: 50%;
  border-radius: 10px 0px 0px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.grayContainer p {
  color: #00193a;
  font-weight: 300;
  font-size: 16px;
  text-align: right;
  margin-right: 22px;
}

.whiteContainer {
  background-color: #ffffff;
  width: 50%;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whiteContainer h4 {
  color: #00193a;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
  width: 241px;
}

.whiteContainer p {
  color: #00193a;
  font-weight: 300;
  font-size: 16px;
  margin-top: 0px;
}

@media (min-width: 768px) and (max-width: 1279.95px) {
  .container {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.27);
  }
  .grayContainer {
    width: 340px;
    border-radius: 10px;
    box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  }
  .grayContainer p {
    color: #00193a;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .whiteContainer {
    margin-top: -20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
    padding-bottom: 20px;
    padding-top: 5px;
  }
  .whiteContainer h4 {
    font-size: 16px;
    width: 197px;
  }
  
  .whiteContainer p {
    color: #00193a;
    font-weight: 300;
    font-size: 12px;
    margin-top: -5px;
  }
}
