.videoContainer {
  width: 100% !important;
  height: 45vh !important;
}

.bgVideo {
  height: 45vh !important;
  width: 100%;
  object-fit: cover;
  background-position: center;
}
