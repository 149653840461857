.formulario-container {
  margin-right: auto;
  width: 100%;
  margin-left: auto;
  box-shadow: 6px 6px 10px 0px rgb(0 0 0 / 17%);
  margin-bottom: 100px;
}

.formulario-datos {
  padding: 50px;
  position: relative;
}

.formulario-saludo {
  color: #00193a;
  font-size: 30px;
  font-weight: 600;
  margin: 0px;
}
.formulario-sub {
  color: #00193a;
  font-size: 26px;
  font-weight: 400;
  margin: 0px;
}
.formulario-area {
  color: #00193a;
}

.fila-formulario {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
.campo-formulario {
  width: 45%;
  padding: 0px 10px;
}

.campo-formulario.archivo input {
  display: flex;
}

.campo-formulario label {
  color: #00193a;
  font-size: 14px;
  line-height: 35px;
}
.campo-formulario input {
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #f4f4f6;
  border-radius: 8px;
  padding-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="file"] {
  margin-left: 10px;
  border: 1px solid #b5bcc7;
  width: 100%;
  text-align: left;
  line-height: 50px;
}

input[type="file"]::-webkit-file-upload-button {
  background: #00193a;
  color: #ffffff;
  line-height: 50px;
  padding: 0px 20px;
  margin-right: 0;
  border: none;
  float: right;
}
input[type="file"] {
  margin: 0 auto;
}

.campo-formulario-textarea {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.campo-formulario-textarea textarea {
  height: 220px;
  resize: none;
  background-color: #f4f4f6;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 20px;
  font-family: "Nunito", sans-serif;
}
.campo-formulario.campo-formulario-textarea {
  padding: 0px;
}

.boton-enviar-formulario-contacto {
  background-color: #00193a;
  color: #fff;
  left: 0px;
  right: 0px;
  padding: 10px 15px;
  width: 100px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: -20px;
  border-radius: 8px;
  cursor: pointer;
}

.fila-formulario.campo-pregunta {
  justify-content: flex-start;
}

.caja-preguntas {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.fila-formulario.campo-pregunta
  .campo-formulario
  .caja-preguntas
  input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #f4f4f6;
  border: 1px solid #00193a;
  margin: 0;
  font: inherit;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.fila-formulario.campo-pregunta
  .campo-formulario
  .caja-preguntas
  input[type="radio"]:checked::before {
  transform: scale(1);
}

.fila-formulario.campo-pregunta
  .campo-formulario
  .caja-preguntas
  input[type="radio"]::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00193a;
}

.error-mensaje-formulario {
  color: #00193a;
  font-size: 14px;
}

.caja-preguntas input {
  padding-left: 0px !important;
}

@media (min-width: 768px) {
  .formulario-container {
    max-width: 860px;
  }
}

@media (max-width: 768px) {
  .formulario-container {
    width: 80%;
    padding: 20px;
  }

  .fila-formulario {
    margin-bottom: 20px;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .formulario-datos {
    padding: 20px;
  }

  .campo-formulario {
    width: 100%;
    padding: 0px;
  }
}
