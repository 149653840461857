.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 110px;
}

.container h2 {
  color: #00193a;
  margin-bottom: 0px;
}

.container hr {
  background-color: #00193a;
  height: 2px;
  width: 30px;
  border: 0px;
}

.titleBigContainer {
  width: 100%;
  top: -180px;
  position: absolute;
  display: flex;
  justify-content: center;
}

.titleContainer {
  height: 418px;
  width: 350px;
  background-color: #00193a;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 430px;
}

.titleContainer h1 {
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
  width: 190px;
  padding-top: 45px;
  margin-left: 63px;
}

.titleContainer p {
  font-weight: 500;
  font-size: 14px;
  width: 230px;
  margin-left: 63px;
}

.descriptionContainer {
  background-color: #f6f6f6;
  border-radius: 0px 0px 10px 10px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
  width: 780px;
  height: 794px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.descriptionElement {
  color: #5f6b7e;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 340px;
  margin-bottom: 30px;
}

.descriptionTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 10px;
}

.itemContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 768px) {
  .titleBigContainer {
    top: -90px;
  }
  .titleContainer {
    height: 320px;
    width: 300px;
    margin-right: 0px;
  }

  .descriptionContainer {
    width: 100vw;
    height: 930px;
    padding-left: 0px;
    padding-right: 0px;

    align-items: center;
  }

  .descriptionElement {
    font-size: 12px;
    line-height: 18px;
    width: 340px;
  }

  .descriptionTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 10px;
  }

  .itemContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .itemContainer img {
    width: 20px;
  }
}
