.contenedor {
  max-width: 1330px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.contenedorGeneral {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.paginacion {
  display: flex;
  gap: 10px;
}

.buttonNumber {
  border: 0px;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 500;
  color: #66666670;
}

.active {
  color: #00193a;
  font-weight: 600;
  cursor: pointer;
}

.disabled {
  filter: grayscale(100%);
  opacity: 20%;
}

.textButton {
  border: 0px;
  background-color: transparent;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.textButton img {
  width: 18px;
}

@media (max-width: 1290px) {
  .contenedor {
    max-width: 800px;
  }
}

@media only screen and (max-width: 768px) {
  .contenedor {
    gap: 10px;
  }
  .buttonNumber {
    font-size: 1rem;
  }
  .textButton img {
    width: 15px;
  }
}
