.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00193a;
  padding: 15px 0 10px 0;
}

.drawer {
  position: relative !important;
}

.drawer .MuiPaper-root {
  width: 100%;
  height: auto;
  top: 86px !important;
  border-radius: 0px 0px 10px 10px;
}

.textItem a {
  color: #00193a;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  padding-right: 30px;
  padding-left: 10px;
}

.burgerCont {
  margin-left: 20px;
}

.burgerCont img {
  width: 20px;
}

.logo img {
  width: 40% !important;
}

.logo {
  text-align: right;
  margin-right: 20px;
}

.MuiBackdrop-root {
  top: 250px !important;
}

.css-1t29gy6-MuiToolbar-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
