.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  justify-content: space-between;
  column-gap: 25px;
}

.flipBox {
  width: 260px;
  height: 245px;
  justify-content: center;
  text-align: center;
  perspective: 1000px;
}

.flipBoxInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipBox:hover .flipBoxInner {
  transform: rotateX(180deg);
}

.flipBoxFront,
.flipBoxBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 17%);
}

.flipBoxFront {
  background-color: #fff;
}

.flipBoxFront img {
  width: 19%;
  margin-left: auto;
  margin-right: auto;
}

.boxTitle {
  color: #082040;
  font-size: 16px;
}

.flipBoxBack {
  background-color: #fff;
  transform: rotateX(180deg);
}

.text {
  padding: 20px;
  color: #082040;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2em;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 20px;
    column-gap: 0px;
    gap: 10px;
    margin-top: -145px;
    justify-content: center;
  }
  .flipBox {
    width: 175px;
    padding: 0px;
    height: 160px;
  }
  .flipBoxBack .text {
    font-size: 10px;
  }
  .boxTitle {
    font-weight: 600;
  }
}
