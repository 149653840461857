.accordion {
  max-width: 397px;
  margin: 20px;
  box-shadow: 8px 6px 10px 0 rgb(0 0 0 / 17%);
  border-radius: 10px;
}

.accordionPortada {
  height: 221px;
  border-radius: 10px;
}

.imageCover {
  height: 221px;
  position: relative;
}

.rotuloEmpresa {
  position: absolute;
  bottom: 0px;
  left: 50px;
}

.mainImage {
  border-radius: 10px;
  height: 221px;
  width: 100%;
  object-fit: cover;
}

.accordionDetail {
  padding-left: 30px;
  padding-right: 30px;
  height: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.accordionDetail h4 {
  color: #00193a;
  font-size: 20px;
  line-height: 24px;
  padding-right: 30px;
}

.accordionDetail p {
  margin-top: -5px;
  font-size: 14px;
  color: #8d90a1;
}

@media (max-width: 1290px) {
  .accordion {
    max-width: 360px;
  }
}

@media only screen and (max-width: 768px) {
  .accordionDetail p {
    font-size: 12px;
  }
  .accordionDetail h4 {
    font-size: 18px;
    line-height: 20px;
  }
  .accordionDetail {
    padding-left: 20px;
    padding-right: 20px;
    height: 220px;
  }
}
