.container {
  height: 200px;
  width: 100%;
  background-color: #f4f4f6;
  margin-bottom: 100px;
}

.contadores {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  text-align: center;
}

.contador {
  width: 100%;
}

.contador span {
  font-weight: 800;
  color: #00193a;
  font-size: 45px;
}

.contadorText {
  color: #00193a;
  font-family: poppins;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0px;
}

@media only screen and (max-width: 768px) {
  .container {
    height: 300px;
  }
  .contadores {
    width: 100vw;
    flex-wrap: wrap;
  }
  .contador {
    width: 50%;
  }
  .contador span {
    font-size: 35px;
  }
}