.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 110px;
}

.header {
  background-color: #00193a;
  display: flex;
  justify-content: center;
  width: 570px;
  border-radius: 5px 5px 0px 0px;
}

.header h2 {
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
}

.text {
  color: #7a7a7a;
  padding-top: 45px;
  padding-bottom: 45px;
  width: 660px;
  border-radius: 5px;
  box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.27);
  background-color: #f6f6f6;
}

.text p {
  padding-left: 45px;
  padding-right: 45px;
  font-size: 14px;
  margin-bottom: 25px;
}

.text b {
    color: #4F5471;
}

@media only screen and (max-width: 768px) {
  .header {
    width: 325px;
  }
  .header h2 {
    font-size: 14px;
    font-weight: 500;
  }
  .text {
    width: 355px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
  }
}