.containerGeneral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.encabezado {
  color: #fff;
  font-size: 22px;
  line-height: 20px;
  font-weight: 500;
  background-color: #00193A;
  width: 710px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
}

.container {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper {
  width: 1319px;
  height: 500px;
}

.swiperSlide {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
}

.item {
  width: 46%;
  height: 482px;
  box-shadow: 8px 6px 10px 0 rgb(0 0 0 / 17%) !important;
  background-color: #fff;
  border-radius: 10px;
}

.portada {
  position: relative;
  height: 46%;
}

.rotulo {
  position: absolute;
  bottom: 0px;
  left: 50px;
}

.mainImage { 
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.text {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 40px;
}

.text h4 {
  color: #00193A;
  font-size: 20px;
  line-height: 24px;
}

.text p {
  margin-top: -5px;
  font-size: 14px;
  color: #8D90A1;
}

@media only screen and (max-width: 768px) {
  .encabezado {
    font-size: 14px;
    line-height: 12px;
    width: 365px;
  }
  .container {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .swiper {
    width: 365px;
    height: 550px;
  }
  .swiperSlide {
    justify-content: center;
    gap: 0px;
  }
  .item {
    width: 340px;
    height: 520px;
  }
  .text {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
  }
  .text h4 {
    padding-right: 30px;
  }
}