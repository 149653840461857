.container {
    background-color: #f4f4f6;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 118px;
    margin-bottom: 150px;
  }
  
  .titleContainer {
    position: absolute;
    top: -30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .title {
    background-color: #00193a;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
  }
  
  .title h2 {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
  }
  
  .swiperContainer {
    width: 73%;
  }
  
  .swiperSlide {
    display: flex;
    justify-content: center;
    height: 130px;
    width: 210px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 5px 5px 5px 0 rgb(0 0 0 / 10%);
    margin-top: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .swiperSlide img {
    width: 130px;
    object-fit: contain;
  }
  
  .swiperSlide:hover {
    transform: scale(1.1);
  }
  
  @media only screen and (max-width: 768px) {
    .container {
      height: 220px;
    }
    .titleContainer {
      top: -20px;
    }
    .title {
      padding-left: 10px;
      padding-right: 10px;
    }
    .title h2 {
      font-weight: 500;
      font-size: 12px;
    }
    .swiperContainer {
      width: 100%;
    }
    .swiperSlide {
      width: 150px;
      height: 90px;
    }
    .swiperSlide img {
      width: 120px;
    }
  }