.accordion {
  max-width: 365px;
  margin: 20px;
  box-shadow: 8px 6px 10px 0 rgb(0 0 0 / 17%);
  border-radius: 10px;
}

.accordionPortada {
  height: 240px;
  border-radius: 10px;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.accordionLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.accordionDetail {
  padding-left: 50px;
  padding-right: 50px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #f4f4f6;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accordionDetail.open {
  height: 280px;
}

.accordionDetail p {
  margin-top: 20px;
  font-size: 14px;
  color: #00193a;
  line-height: 1.2;
}

.accordionButton {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 140px;
  padding: 10px;
  border: 2px #b9b9b9 solid;
  border-radius: 10px;
  color: #00193a;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}

.accordionButton a {
  color: #00193a;
  text-decoration: none;
}

@media (max-width: 1290px) {
  .accordionDetail.open {
    height: 290px;
  }
}

@media only screen and (max-width: 768px) {
  .accordion {
    margin: 0px;
  }
  .accordionPortada {
    height: 160px;
  }
  .accordionDetail {
    padding-left: 15px;
    padding-right: 20px;
  }
  .accordionDetail.open {
    height: 190px;
  }
  .accordionDetail p {
    margin-top: 15px;
    font-size: 12px;
  }
  .accordionButton {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100px;
    padding: 5px;
    font-size: 12px;
  }
}
